import { IconButton, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import ActivityCard from "./ActivityCard"
import trash from "../../../images/trash-icon.svg"
import DeleteConfirmModal from "../../components/modals/DeleteConfirmModal"
import { useDispatch, useSelector } from "react-redux"
import { showDeleteConfirmModal } from "../../../redux/slices/deleteConfirmModalSlice"
import { COLORS } from "../../../utils/theme"
import apiClient from "../../../api/apiClient"
import { activityApi } from "../../../api/apiRequests"
import { setSuccessAlert } from "../../../redux/slices/errorAlertSlice"
import { removeActivity } from "../../../redux/slices/activitiesSlice"
import noUserImg from "../../../images/no-user-image.png"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  iconContainer: {
    backgroundColor: "#f8f8f8ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "8px",
    marginRight: "10px",
    cursor: "pointer",
  },
}))

function YourActivity({ close, activity }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const activityID = activity?.uuid

  console.log("ac", activityID)

  const availableSpot =
    activity?.players?.length > 0 &&
    activity?.maxPlayerCount - activity?.players?.length

  const [loading, setLoading] = useState(false)

  const isVisible = useSelector(
    state => state.deleteConfirmModalSlice.isVisible
  )

  const openDeleteConfirmModal = () => {
    dispatch(showDeleteConfirmModal(true))
  }

  const arr = [1, 2, 3, 4, 5, 6, 7]

  const handleDeleteActivity = async () => {
    setLoading(true)
    try {
      const res = await apiClient(activityApi.deleteActivity(activity.uuid))
      dispatch(showDeleteConfirmModal(false))
      dispatch(removeActivity({ id: activityID }))
      dispatch(
        setSuccessAlert({
          isVisible: true,
          message: "Activity deleted successfully",
          title: "success",
          severity: "success",
        })
      )
      close()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <div>
          <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
            Your Activity
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            className={classes.iconContainer}
            onClick={openDeleteConfirmModal}
          >
            <img src={trash} alt="" />
          </div>
          <IconButton onClick={close}>
            <CloseIcon style={{ margin: "13px" }} />
          </IconButton>
        </div>
      </div>
      <ActivityCard activity={activity} viewDetails={false} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          paddingRight: "80px",
        }}
      >
        {activity?.players.map(item => (
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginRight: "15px",
                }}
              >
                <img
                  src={item.profilePictureStorageUrl || noUserImg}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                  alt=""
                />
                <div
                  style={{
                    backgroundColor: "#FDED9E",
                    width: "18px",
                    height: "18px",
                    borderRadius: "50%",
                    position: "absolute",
                    left: "32px",
                    bottom: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {item.level[activity.gameType]
                    ? item.level[activity.gameType]
                    : "3"}
                </div>
              </div>
              <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
                {item.name.full}
              </Typography>
            </div>
          </div>
        ))}
        {activity?.players?.length > 0 &&
          [...Array(availableSpot)].map((_, index) => (
            <div
              key={index}
              style={{
                width: "48%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: COLORS.racqyLightBlue,
                  border: "2px dashed #209CA3",
                  marginRight: "15px",
                }}
              />
              <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
                Available Spot
              </Typography>
            </div>
          ))}
      </div>
      {isVisible && (
        <DeleteConfirmModal
          header="Are you sure you want to delete this activity?"
          info="Once deleted, the activity will not be able to retrieve."
          buttonTitle="Delete Activity"
          onClick={handleDeleteActivity}
          loading={loading}
        />
      )}
    </div>
  )
}

export default YourActivity
