import React from "react"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Backdrop from "@material-ui/core/Backdrop"
import CrossIcon from "../../../../static/images/cross-icon-racqy.svg"
import { COLORS } from "../../../utils/theme"
import Button from "@material-ui/core/Button"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles, Typography } from "@material-ui/core"
import { showDeleteConfirmModal } from "../../../redux/slices/deleteConfirmModalSlice"
import Spinner from "../Loader/Spinner"

const useStyles = makeStyles(theme => ({
  deleteButton: {
    background: "#FD6E84",
    border: 0,
    borderRadius: 8,
    fontFamily: "DM Sans",
    color: "white",
    height: 38,
    width: 130,
    fontWeight: "bold",
    padding: "0 10px",
    textTransform: "none",
    "&:hover": {
      background: "#f33c58",
    },
  },
  cancelButton: {
    border: 0,
    borderRadius: 8,
    fontFamily: "DM Sans",
    fontWeight: "bolder",
    color: "#000000",
    textTransform: "none",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "45%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: COLORS.white,
    border: "0px",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: 24,
    paddingTop: "20px",
    paddingBottom: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // maxHeight: "230px" /* Set a fixed height */,
    minHeight: "170px",
  },
  crossContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  crossIcon: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
  },
}))

export default function DeleteConfirmModal({
  header,
  info,
  buttonTitle,
  onClick,
  loading,
}) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const isVisible = useSelector(
    state => state.deleteConfirmModalSlice.isVisible
  )

  const closeModal = () => {
    dispatch(showDeleteConfirmModal(false))
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={true}>
        <Box border={"none"} className={classes.box}>
          <div className={classes.crossContainer} onClick={closeModal}>
            <img src={CrossIcon} className={classes.crossIcon} alt="" />
          </div>
          <div
            style={{
              padding: "0px 20px",
              marginTop: "20px",
            }}
          >
            <Typography
              id="transition-modal-title"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {header}
            </Typography>
            <Typography
              id="transition-modal-description"
              style={{ marginTop: "15px", textAlign: "center" }}
            >
              {info}
            </Typography>
          </div>
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button className={classes.cancelButton} onClick={closeModal}>
              Cancel
            </Button>
            {loading ? (
              <Spinner />
            ) : (
              <Button className={classes.deleteButton} onClick={onClick}>
                {buttonTitle}
              </Button>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
